<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">

      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t('general.institutions') }}</li>
            <li>{{ $t('institution.list.company_list') }}</li>
          </ul>
        </nav>
      </div>

      <div v-if="successMessage" class="card">
        <div class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color:green;">
            <i class="uil-exclamation-triangle"></i> {{ $t('general.update_success') }}
          </p>
        </div>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span> {{ $t('institution.list.company_list') }} </span></h2>
        </div>
        <div class="section-header-right">
          <router-link :to="{ name: 'company.institutions.add' }">
            <button class="btn btn-default" type="button" aria-expanded="false"><i class="uil-plus"></i>
              {{ $t('institution.list.add_company') }}</button>
          </router-link>
        </div>
      </div>

      <div class="card" v-if="loading">
        <default-loading></default-loading>
      </div>
      <div class="card" v-else>
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <!-- <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  @change="filterResult" :placeholder="$t('general.search')" autofocus="" />
              </div>
            </div> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  @change="filterResult" :placeholder="$t('general.search')" />
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('name', 'asc')">{{
                    $t('filters.order.a_z') }}</a></li>
                  <li><a href="javascript:void(0)" @click="sortOrder('name', 'desc')">{{ $t('filters.order.z_a') }}</a>
                  </li>
                  <li><a href="javascript:void(0)" @click="sortOrder('created_at', 'asc')"> {{
                    $t('filters.order.created_at_asc') }}</a></li>
                  <li><a href="javascript:void(0)" @click="sortOrder('created_at', 'desc')"> {{
                    $t('filters.order.created_at_desc') }}</a></li>
                </ul>
              </div>

              <!-- Export -->

              <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
                @click="exportData">
                <i class="fa-file-excel fa"></i>
              </a>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t('institution.list.company_name') }}</th>
                <th scope="col">{{ $t('institution.list.contract_expired_date') }}</th>
                <th scope="col">{{ $t('institution.list.created_at') }}</th>
                <th scope="col">{{ $t('Kullanıcı Sayısı') }}</th>
                <th scope="col"> </th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="item in formattedItem">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <img alt="Image placeholder" :src="item.logo_link" class="avatar  rounded-circle">
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <a class="name h6 mb-0 text-sm">{{ item.name }}</a>
                      <small class="d-block font-weight-bold">#{{ item.id }}</small>
                    </div>
                  </div>
                </th>
                <td>{{ item.end_date }}</td>
                <td> {{ item.created_at }} </td>
                <td> {{ item.users_count }} </td>
                <td class="text-right">
                  <div class="actions ml-3">
                    <router-link :to="{ name: 'company.institutions.edit', params: { id: item.id } }"
                      class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.edit')" title=""
                      aria-expanded="false">
                      <i class="uil-edit"></i>
                    </router-link>
                    <a v-if="item.status_id == 1" @click="statusChange(item.id, 0)"
                      class="btn btn-icon btn-hover btn-sm btn-circle" :uk-tooltip="$t('general.do_passive')" title=""
                      aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                    <a v-else @click="statusChange(item.id, 1)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      :uk-tooltip="$t('general.do_active')" title="" aria-expanded="false">
                      <i class="uil-check text-success"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <pagination :pagination="pagination" @changePage="setPage($event)"></pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ERROR,
  UPDATE_ITEM,
  ITEMS,
  LOADING,
  SUCCESS
} from "@/core/services/store/company.module";
import store from '@/core/services'
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

export default {
  name: "InstitutionList",
  components: { Pagination, DefaultLoading },
  data() {
    return {
      type: "institution",
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      filter: {
        search: null,
        order_by: null,
        sort: null
      },
      pagination: null,
      successMessage: "",
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + '/' + ITEMS]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
    success() {
      return store.getters[MODULE_NAME + '/' + SUCCESS]
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      for (let i = 0; i < this.items.data.length; i++) {
        let currentItem = this.items.data[i];
        this.pagination = this.items;
        if (currentItem) {

          results.push({
            id: currentItem.id,
            logo_link: currentItem.logo_link,
            name: currentItem.name,
            status_id: currentItem.status_id,
            users_count: currentItem.users_count,
            created_at: currentItem.created_at ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm:ss') : '-',
            end_date: currentItem.contract ? moment(currentItem.contract.end_date).format('DD/MM/YYYY HH:mm:ss') : '-',
          });
        }
      }
      return results;
    }
  },
  methods: {
    exportData() {
      axios.get(process.env.VUE_APP_BACKEND_APP_URL + '/api/company/export', {
        responseType: 'blob',
        params: {
          sort: this.filter.sort,
          order_by: this.filter.order_by,
          like: this.filter.search,
          type: "institution"
        },
        headers: this.apiHeaders,
      }).then((response) => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let day = moment().format('YYYYMMDDHHMMSS');
        let fileName = 'company_list' + day + '.xlsx';
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
    },
    statusChange(id, status_id) {
      let formData = new FormData();
      formData.append('company', id);
      formData.append('status_id', status_id);

      store.dispatch(MODULE_NAME + '/' + UPDATE_ITEM, {
        url: BASE_URL + '/status/' + id,
        contents: formData,
        returnType: "stateless",
        acceptPromise: true,
      }).then(response => {
      });
    },

    filterResult() {
      this.$store.dispatch(MODULE_NAME + '/' + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          type: this.type,
          like: this.filter.search,
          page: this.page,
          per_page: this.per_page,
          order_by: this.filter.order_by,
          sort: this.filter.sort,
        }
      });
    },
    sortOrder(order_by, sort) {
      this.page = 1;
      this.filter.order_by = order_by;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult()
    }

  },
  mounted() {
    this.filterResult();

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult()
      }
    },
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
